import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {node} from 'prop-types';
import dayjs from 'dayjs';
import axios from '../api/axios';
import {CURRENT_REGULATIONS_VERSION} from '../constants.js';

const AuthContext = createContext({
    isAuthenticated: null,
    user: null,
    isStatuteAccepted: null,
    login() {},
    logout() {},
    acceptRegulations() {},
    updateUserSettings() {},
});

export function AuthProvider({children}) {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isStatuteAccepted, setIsStatuteAccepted] = useState(null);

    useEffect(() => {
        let isMounted = true;
        axios
            .get('user')
            .then((response) => {
                if (isMounted) {
                    // check if fetched user id is equal to user_id from local storage
                    if (
                        response.data.user_id.toString() ===
                        localStorage.getItem('user_id') &&
                        response.data.session_id === localStorage.getItem('session_id') &&
                        localStorage.getItem('session_lifetime') >= dayjs().unix()
                    ) {
                        setUser(response.data);
                        console.debug('Fetched user data: ', response.data);
                        setIsAuthenticated(true);
                        localStorage.setItem('session_id', response.data.session_id);
                        localStorage.setItem(
                            'session_lifetime',
                            dayjs().add(response.data.session_lifetime, 'seconds').unix()
                        );
                        localStorage.setItem('lifetime', response.data.session_lifetime);
                        console.debug('User is authenticated');
                    } else {
                        setIsAuthenticated(false);
                        console.warn('User is not authenticated');
                    }
                }
            })
            .catch((error) => {
                console.debug('Cannot fetch user data: ', error.response.data);
                setIsAuthenticated(false);
            });

        return () => {
            isMounted = false;
        };
    }, [axios]);

    useEffect(() => {
        if (!user) return;
        if (
            user.settings &&
            user.settings.regulations &&
            user.settings.regulations === 'accepted' &&
            user.settings?.regulations_version &&
            user.settings.regulations_version ===
            CURRENT_REGULATIONS_VERSION
        ) {
            setIsStatuteAccepted(true);
        } else {
            setIsStatuteAccepted(false);
        }
    }, [user]);

    const updateCurrentUser = (data) => {
        setUser((prev) => ({...prev, ...data}));
    };

    const login = useCallback(async (data) => {
        setUser(data);
        setIsAuthenticated(true);
        localStorage.setItem('user_id', data.user_id);
        localStorage.setItem('session_id', data.session_id);
        localStorage.setItem(
            'session_lifetime',
            dayjs().add(data.session_lifetime, 'seconds').unix()
        );
        localStorage.setItem('lifetime', data.session_lifetime);
    }, []);

    const logout = useCallback(() => {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem('user_id');
        localStorage.removeItem('session_id');
        localStorage.removeItem('session_lifetime');
    }, []);

    const acceptRegulations = useCallback(async (data) => {
        axios
            .post('user', data)
            .then((response) => {
                setIsStatuteAccepted(true);
                updateCurrentUser(data);
                console.debug('User settings updated', response.data);
            })
            .catch((error) => {
                console.debug('Update user settings failed', error.response.data);
                setIsStatuteAccepted(false);
            });
    }, []);

    const updateUserSettings = useCallback((settings) => {
        axios
            .post('user', {settings: settings})
            .then((response) => {
                updateCurrentUser({settings: settings});
                console.debug('User settings updated', response.data);
            })
            .catch((error) => {
                console.debug('Update user settings failed', error.response.data);
            });
    }, []);

    const value = useMemo(
        () => ({
            user,
            isStatuteAccepted,
            isAuthenticated,
            updateCurrentUser,
            login,
            logout,
            acceptRegulations,
            updateUserSettings
        }),
        [
            user,
            isStatuteAccepted,
            isAuthenticated,
            updateCurrentUser,
            login,
            logout,
            acceptRegulations,
            updateUserSettings
        ]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }

    return context;
}

AuthProvider.propTypes = {
    children: node,
};
